@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '@client/app/components/App/Header/Autosuggest/design-tokens';

.Autosuggest-List {
  &.Autosuggest-List--Category {
    margin-bottom: $cds-spacing-2;
  }
}

.Autosuggest-ListItem--Header {
  padding: 0 $cds-spacing-2;
}

.Autosuggest-ListLink {
  &.Autosuggest-ListLink--Category {
    @include cds-link-colors($cds-color-link, $cds-color-link-hover, $cds-color-link);

    padding-left: $cds-spacing-4;
  }
}

.Autosuggest-ListLabel {
  color: $cds-color-text-body;
}

.Mobile {
  .Autosuggest-List {
    &.Autosuggest-List--Category {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }

  .Autosuggest-ListItem--Header {
    @include media-breakpoint-down(xs) {
      padding: 0 $cds-spacing-1;
    }
  }

  .Autosuggest-ListLink {
    &.Autosuggest-ListLink--Category {
      @include media-breakpoint-down(xs) {
        padding-left: $cds-spacing-3;
      }
    }
  }
}
