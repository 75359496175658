.ToastWrapper {
  background: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

@keyframes slide-in {
  from {
    transform: translate(110%, 0%);
    visibility: visible;
  }

  to {
    transform: translate(0%, 0%);
  }
}

.ToastWrapper-AnimationEnter {
  animation: slide-in 0.2s cubic-bezier(0.04, 0.8, 0.33, 1.05);
}

@keyframes slide-out {
  from {
    transform: translate(0%, 0%);
  }

  to {
    transform: translate(110%, 0%);
    visibility: hidden;
  }
}

.ToastWrapper-AnimationLeave {
  animation: slide-out 0.3s ease-in;
}
