@import '~b2b-components/theme/styles/globals';

.CodeScanner {
  height: 100%;
  position: relative;
  width: 100%;

  .scandit-progressbar-container [role="progressbar"][aria-valuenow]::before {
    background-color: $cds-color-primary;
  }

  .scandit-progressbar-container [role="progressbar"]::after {
    background-color: $cds-color-primary;
  }
}
