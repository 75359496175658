@import '~@lib/styles/globals';

.AddToCart-Icon--Hidden {
  visibility: hidden;
}

.AddToCart-TooltipWrapper {
  z-index: $cds-zindex-popover;
}

.AddToCart-Tooltip--CoreAssortment {
  text-align: center;
  white-space: normal;
  width: 200px;
}
