@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

$logo-height: $cds-sizing-5;
$logo-height-mobile: $cds-sizing-3_5;

.Logo {
  height: $logo-height;
  margin-right: auto;

  img {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    height: $logo-height-mobile;
  }
}
