@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.ProductListModuleV2--HomePage {
  .Content--CMS & {
    @include cms-grid-col(12, 12);
  }
}

.ProductListModuleV2-SliderContainer {
  padding: $cds-sizing-0_5;

  .ProductListModuleV2-ProductBox {
    flex-shrink: 0;
    margin-right: $cds-sizing-2;

    @include media-breakpoint-up(sm) {
      flex-basis: calc(50% - #{$cds-spacing-1_5});
    }

    @include media-breakpoint-up(xl) {
      flex-basis: calc(25% - #{$cds-spacing-1_5});
    }
  }
}

.ProductListModuleV2-ProductBox--Slider {
  height: auto;
}

.ProductListModuleV2-ListWrapper--Table {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.cds-cms-LayoutBlock--Full {
  .cds-cms-ModuleContainer:not(.ProductListModuleV2--HomePage) {
    .ProductListModuleV2-SliderContainer {
      .ProductListModuleV2-ProductBox--Slider {
        /*
          hack for swiper - when swiper wrapper is wide with a floating point number,
          in certain resolution borders for product boxes disappear
          necessary for cms pages
        */
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 1px $cds-color-border;
        margin-right: $cds-sizing-2;
        width: $cds-sizing-32_5;
      }
    }
  }
}
