@import 'node_modules/bootstrap/scss/mixins';
@import '@client/cart/components/AddToCart/CertificationInfo/design-tokens';

.CertificationInfo-Tooltip {
  font-size: 0;
}

.CertificationInfo-Icon {
  @include cds-border;

  background-color: $certification-info-icon-bg;
  border: 0;
  fill: $certification-info-icon-color;
  padding: $cds-spacing-0_5;
}

.CertificationInfo--Full {
  @include cds-typescale(xsmall, $line-height: $cds-line-height-condensed);

  align-items: center;
  color: $certification-info-color;
  display: flex;
  margin-top: $cds-spacing-1;

  .CertificationInfo-Icon {
    align-self: flex-start;
    flex-shrink: 0;
    margin-right: $cds-spacing-1;

    @include media-breakpoint-down(xs) {
      @include cds-box($cds-sizing-5);
    }
  }

  @include media-breakpoint-down(xs) {
    margin: 0;
  }
}

.CertificationInfo--Icon {
  & .CertificationInfo-Tooltip {
    display: unset;
  }

  & .CertificationInfo-TooltipTrigger {
    margin: unset;
    padding: unset;
  }
}
