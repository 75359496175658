@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.Mobile {
  padding: $cds-spacing-1 0;

  .cds-Row {
    &:first-child {
      margin-bottom: $cds-spacing-1;
    }
  }

  .cds-Row--CenterVertical {
    & > .cds-Button {
      margin-right: $cds-spacing-1;
    }
  }

  .cds-SearchField {
    height: $cds-sizing-4;
  }
}
