@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import '~@client/common/components/InfoView/design-tokens';

.InfoView {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-top: $cds-spacing-10;
  text-align: center;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: $cds-spacing-6;
  }
}

.InfoView-ImageWrapper {
  @include cds-box($cds-sizing-25);

  align-items: center;
  background-color: $image-background-color;
  border: 1px solid $image-border-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0 auto $cds-spacing-2;
}

.InfoView-Image {
  max-height: $cds-sizing-16;
  max-width: $cds-sizing-20;
}

.InfoView-Content {
  margin-bottom: $cds-spacing-2;
}

.InfoView-Title {
  display: flex;
  justify-content: center;
}

.InfoView-TitleIcon {
  margin-right: $cds-spacing-2;
}

.InfoView-TitleIcon--Info {
  fill: $cds-color-info;
}

.InfoView-TitleIcon--Success {
  fill: $cds-color-success;
}

.InfoView-TitleIcon--Warning {
  fill: $cds-color-warning;
}

.InfoView-TitleIcon--Error {
  fill: $cds-color-error;
}

.InfoView-TitleText {
  @include cds-typescale(6, 0.5);

  color: $cds-color-text-primary;
  font-weight: $cds-font-weight-semibold;

  @include media-breakpoint-down(sm) {
    @include cds-typescale(h1m, 0.5);
  }
}

.InfoView-MessageText {
  @include cds-typescale(body, 0.5);
}

.InfoView-ButtonGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .cds-Button {
    margin: 0 $cds-spacing-1_5 $cds-spacing-1;
  }
}
