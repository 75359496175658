@import '~b2b-components/theme/styles/globals';

.ServerFailMessage {
  display: flex;
}

.ServerFailMessage-Icon {
  margin-right: $cds-spacing-1;

  & .cds-Icon {
    fill: $cds-color-warning;
  }
}

.ServerFailMessage-Link {
  text-decoration: underline;
}

.ServerFailMessage-TextLine:not(:last-child) {
  margin-bottom: $cds-spacing-1_5;
}
