@import '~b2b-components/theme/styles/globals';

.DisabledCookiesPage-Content {
  max-width: 640px;
}

.DisabledCookiesPage-TextLine {
  margin-bottom: $cds-spacing-1;
}

.DisabledCookiesPage-List {
  padding-left: $cds-spacing-3_5;
}

.DisabledCookiesPage-Link {
  @include cds-link-colors;
}
