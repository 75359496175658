@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';
@import '@client/app/components/design-tokens';

/* ==========================================================================
   Main App Styles
   ========================================================================== */

/* Main App Container
   ========================================================================== */

.App {
  background-color: $app-bg;
  display: flex;
  flex-direction: column;
  font-family: $cds-font-family-base;
  min-height: 100vh;
}

.App-Header {
  background-color: $header-bg;
  position: relative;
  z-index: $cds-zindex-header–fixed;
}

.App-HeaderSticky {
  background-color: $header-fixed-bg;
  z-index: $cds-zindex-sticky;

  .App-Header {
    background-color: $header-fixed-bg;
    box-shadow: $cds-box-shadow-bottom;
    display: block;
    z-index: $cds-zindex-sticky;
  }
}

/* Main Content Container
   ========================================================================== */

.Main {
  margin-bottom: $cds-spacing-7;
  margin-top: $cds-spacing-2;

  @include media-breakpoint-down(sm) {
    margin-bottom: $cds-spacing-4;
    margin-top: $cds-spacing-1;
  }

  &--ProductPage {
    background-color: $cds-color-white;
    margin-top: 0;
    padding-top: $cds-spacing-2;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding-top: $cds-spacing-1;
    }
  }
}
