@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import '@client/product/components/ProductData/ProductData/design-tokens';

.BackToTop-Comparison {
  bottom: calc($cds-spacing-8 + $cds-spacing-2_5);
}

.BackToTop-CookieBanner {
  bottom: calc($cds-spacing-8 + $cds-spacing-2_5);

  @include media-breakpoint-down(md) {
    bottom: calc($cds-spacing-16 + $cds-spacing-2 + $cds-spacing-2_5);
  }
}

.BackToTop-StickyFooter {
  @include media-breakpoint-down(md) {
    bottom: calc($cds-spacing-7 + env(safe-area-inset-bottom) + $cds-spacing-2_5);
  }
}
