@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.ProductBox-HiddenPrice {
  .IconHiddenPrice-Currency {
    @include cds-typescale(1);

    margin-right: $cds-spacing-0_5;
  }

  .cds-ProductBox {
    &.cds-ProductBox--List {
      & .IconHiddenPrice-Currency {
        @include cds-typescale(3);

        margin-right: $cds-spacing-1;

        @include media-breakpoint-down(md) {
          @include cds-typescale(1);

          margin-right: $cds-spacing-0_5;
        }
      }
    }
  }

  .cds-ProductBoxTable-BodyRow & {
    justify-content: flex-end;
  }
}

.cds-ProductBox-Action--Hidden {
  visibility: hidden;
}

.cds-ProductBox {
  .cds-ProductBox-Actions:has(.CertificationInfo) {
    display: flex;

    > .cds-ProductBox-Action-AddToCompare {
      visibility: hidden;
    }

    > .cds-ProductBox-Action-AddToWishlist {
      visibility: hidden;
    }
  }
}

.cds-ProductBox:has(.CertificationInfo) {
  &:hover {
    .cds-ProductBox-Actions {
      > .cds-ProductBox-Action-AddToCompare {
        visibility: visible;
      }

      > .cds-ProductBox-Action-AddToWishlist {
        visibility: visible;
      }
    }
  }
}

.cds-ProductBox:has(.AddToCompareAction--Ready) {
  @include media-breakpoint-down(md) {
    .cds-ProductBox-Actions {
      > .cds-ProductBox-Action-AddToWishlist {
        visibility: visible;
      }

      > .cds-ProductBox-Action-AddToCart {
        visibility: visible;
      }
    }
  }

  &:hover {
    .cds-ProductBox-Actions {
      > .cds-ProductBox-Action-AddToWishlist {
        visibility: visible;
      }

      > .cds-ProductBox-Action-AddToCart {
        visibility: visible;
      }
    }
  }
}

.cds-ProductBox-Actions:has(.AddToCompareAction--Ready) {
  display: flex;

  > .cds-ProductBox-Action-AddToWishlist {
    visibility: hidden;
  }

  > .cds-ProductBox-Action-AddToCart {
    visibility: hidden;
  }
}

.cds-ProductBox:has(.CertificationInfo):has(.AddToCompareAction--Ready) {
  display: flex;

  .cds-ProductBox-Actions {
    > .cds-ProductBox-Action-AddToCompare {
      visibility: hidden;
    }

    > .cds-ProductBox-Action-AddToWishlist {
      visibility: hidden;
    }

    > .cds-ProductBox-Action-AddToCart {
      visibility: visible;
    }
  }

  &:hover {
    .cds-ProductBox-Actions {
      > .cds-ProductBox-Action-AddToCompare {
        visibility: visible;
      }

      > .cds-ProductBox-Action-AddToWishlist {
        visibility: visible;
      }
    }
  }
}
