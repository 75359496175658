@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.LogoSliderModule {
  .swiper-wrapper {
    align-items: center;
  }
}

.LogoSliderModule-Logo {
  display: block;
  margin-right: $cds-sizing-4;
  width: $cds-sizing-10;

  @include media-breakpoint-down(sm) {
    margin-right: $cds-sizing-2;
    width: $cds-sizing-7;
  }
}

.LogoSliderModule-LogoImage {
  max-width: 100%;

  .Content--CMS & {
    margin-bottom: 0;
  }
}
