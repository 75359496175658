@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import './design-tokens';

.IconHiddenPrice {
  align-items: center;
  display: flex;

  .cds-Tooltip-Trigger {
    display: flex;
  }
}

.IconHiddenPrice-Currency {
  color: $icon-hidden-price-color;
  margin-right: $cds-spacing-0_5;
}

.IconHiddenPrice-Icon {
  fill: $icon-hidden-price-icon-fill;
}

.IconHiddenPrice-TooltipContent {
  @include cds-typescale(xsmall, $line-height: $cds-line-height-condensed);

  color: $icon-hidden-price-color;
}
