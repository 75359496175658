@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';
@import '@client/app/components/design-tokens';

/* ==========================================================================
   Common App Styles
   ========================================================================== */

/* Typography
   ========================================================================== */

.PageTitle {
  @include cds-typescale(h1, 1);

  @include media-breakpoint-down(sm) {
    @include cds-typescale(h1m, 0.5);
  }
}

/* Generic Page Layout
   ========================================================================== */

.Content {
  margin: 0;
}

.Content--CMS {
  // limit width of text lines
  max-width: $page-content-max-width;

  iframe {
    max-width: 100%;
  }
}

.Content--WithoutBreadcrumbs {
  margin-top: $cds-spacing-4;
}

.Content–Scrollable {
  overflow-x: auto;
}

// wrapper for a piece of individual content on page separated vertically
.Content-Block {
  margin-bottom: $cds-spacing-4;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Product Table
   ========================================================================== */

.ProductTable {
  .cds-DataPair {
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }
}

.ProductTable-BodyCell--Thumbnail {
  width: $cds-sizing-6;
}

.ProductTable-Thumbnail {
  display: flex;
  flex: auto;
  max-height: $cds-sizing-6;
  max-width: $cds-sizing-6;
}

.ProductTable-Title {
  @include cds-typescale(small, $line-height: $cds-line-height-condensed);

  color: $product-list-title-color;
  font-weight: $cds-font-weight-semibold;
}

.ProductTable-Reference {
  @include cds-typescale(xsmall, $line-height: $cds-line-height-condensed);

  color: $product-list-reference-color;
  margin-top: $cds-spacing-1;
  max-width: $product-list-reference-max-width;
}

.ProductTable-Quantity {
  font-weight: $cds-font-weight-semibold;
}

.ProductTable-Warranty {
  white-space: nowrap;
}

/* Modal
   ========================================================================== */

.Modal-Content {
  margin-bottom: $cds-spacing-5;

  & + .Modal-ContentAlert {
    margin-top: -$cds-spacing-5;
  }
}

.Modal-ContentAlert {
  margin-bottom: $cds-spacing-3;
  margin-top: $cds-spacing-2;
}

.Modal-ButtonsWrapper {
  display: flex;
}

.Modal-ButtonsWrapper--Center {
  justify-content: center;
}

.Modal-ButtonDiscard {
  margin-right: auto;
}

.Modal-ButtonApprove {
  margin-left: $cds-spacing-1;
}

/* Modal In Pogress
   ========================================================================== */

.ModalInProgress-Title {
  text-align: center;
}

.ModalInProgress-ModalContent {
  margin-bottom: $cds-spacing-3;
  margin-top: $cds-spacing-4;
  position: relative;
}

.ModalInProgress-Image {
  display: block;
  margin: 0 auto;
}

.ModalInProgress-Dot {
  @include cds-box($modal-in-progress-dot-size);
  @include cds-pos(absolute, $top: 50%);

  background-color: $cds-color-brand;
  border-radius: 50%;
}

.ModalInProgress-Dot--Dot1 {
  left: calc(50% - 4 * #{$modal-in-progress-dot-size});
}

.ModalInProgress-Dot--Dot2 {
  left: calc(50% - 2 * #{$modal-in-progress-dot-size});
}

.ModalInProgress-Dot--Dot3 {
  left: 50%;
}

.ModalInProgress-Dot--Dot4 {
  left: calc(50% + 2 * #{$modal-in-progress-dot-size});
}

.ModalInProgress-Dot--Dot5 {
  left: calc(50% + 4 * #{$modal-in-progress-dot-size});
}
