@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@client/app/components/App/FeatureOnboarding/ModalContent/design-tokens';

.ModalContent-Title {
  font-weight: $cds-font-weight-bold;
  margin-bottom: $cds-spacing-2;
}

.ModalContent-Subtitle {
  color: $feature-onboarding-subtitle-color;
  font-weight: $cds-font-weight-semibold;
  margin-bottom: $cds-spacing-2;
}

.ModalContent-Content {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}

.ModalContent-TextWrapper {
  flex: 1 0 50%;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: $cds-spacing-2;
  }
}

.ModalContent-ImageWrapper {
  padding-left: $cds-spacing-1;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin-top: $cds-spacing-1;
    padding-left: 0;
  }
}

.ModalContent-Image {
  max-height: $cds-sizing-40;
  max-width: $cds-sizing-40;
  width: 100%;
}

.ModalContent-ButtonsWrapper {
  margin-top: $cds-spacing-2;
}
