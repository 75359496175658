@import '@lib/styles/globals';

$app-bg: $cds-color-bg;
$badge-color: $cds-color-error-text;
$header-bg: $cds-color-bg;
$header-fixed-bg: $cds-color-bg;
$modal-in-progress-dot-size: $cds-sizing-0_5;
$page-content-max-width: 48rem;
$product-list-reference-color: $cds-color-text-secondary;
$product-list-reference-max-width: 70ch;
$product-list-title-color: $cds-color-text-primary;
