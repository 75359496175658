@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';

.AddToCompareAction--Hidden {
  visibility: hidden;
}

.AddToCompareAction-TooltipLink {
  display: flex;
  justify-content: center;
}

.AddToCompareAction-TooltipTrigger {
  margin: unset;
  padding: unset;
}

.AddToCompareAction-TooltipText {
  text-align: center;
}

.AddToCompareAction-TooltipContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 240px;
  position: relative;
  white-space: unset;
}

.AddToCompareAction-TooltipContent::before {
  bottom: 50%;
  top: -5px;
}
