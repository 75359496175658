@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.AddToWishListModal {
  overflow-y: visible;

  .cds-SearchableSelect-OptionValue {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
