@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@client/app/components/App/Header/Autosuggest/design-tokens';

.Autosuggest {
  @include cds-pos(absolute, $top: 100%);

  background-color: $cds-color-black-opacity;
  height: 100vh;
  width: 100%;
}

.Autosuggest-Content {
  @include cds-border(all, bottom-left bottom-right);
  @include cds-box-arrow;
  @include cds-pos(relative);

  background-color: $autosuggest-content-bg;
  box-shadow: $cds-box-shadow-bottom;
  display: flex;
  margin-left: $autosuggest-content-margin-left;
  padding: $cds-spacing-2 0;

  &::after,
  &::before {
    left: $autosuggest-arrow-left;

    @include media-breakpoint-down(md) {
      left: $autosuggest-arrow-left-md;
    }
  }

  &::after {
    bottom: 100%;
  }

  &::before {
    bottom: calc(100% + 1px);
  }
}

.Autosuggest-Suggestions {
  flex: 1 1 40%;

  @include media-breakpoint-down(xl) {
    flex-basis: 30%;
  }
}

.Autosuggest-List {
  @include cds-list-unstyled;
  @include cds-typescale(small);

  color: $autosuggest-list-color;
}

.Autosuggest-ListLink {
  color: $autosuggest-list-link-color;
  display: block;
  padding: 0 $cds-spacing-2;

  &:hover {
    color: $autosuggest-list-link-color;
    text-decoration: none;
  }

  &.Autosuggest-ListLink--Active {
    background-color: $autosuggest-list-link-hover-bg;
    color: $autosuggest-list-link-color;
    text-decoration: none;
  }
}

.Autosuggest-Title {
  @include cds-typescale(xxsmall, 0.25);

  color: $autosuggest-title;
  margin-left: $cds-spacing-2;
  text-transform: uppercase;
}

.Autosuggest-Title--Result {
  margin-left: 0;
}

.Autosuggest-Results {
  @include cds-border(left, none);

  flex: 1 1 60%;
  padding: 0 $cds-spacing-1;
}

.Mobile {
  .Autosuggest {
    z-index: $cds-zindex-sticky;
  }

  .Autosuggest-Container {
    @include cds-box-arrow;

    position: relative;

    &::after,
    &::before {
      left: $autosuggest-arrow-left-mobile;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &::after {
      bottom: calc(100% - 1px);
    }

    &::before {
      bottom: 100%;
    }

    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  .Autosuggest-Content {
    @include cds-border(all, all);

    margin-left: 0;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: auto;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    @include media-breakpoint-down(xs) {
      @include cds-border(bottom top, none);
    }
  }

  .Autosuggest-Results {
    padding: 0 $cds-spacing-2;

    @include media-breakpoint-down(sm) {
      @include cds-border(top, none);

      padding-top: $cds-spacing-1;
    }

    @include media-breakpoint-down(xs) {
      padding: $cds-spacing-1 $cds-spacing-1 0;
    }
  }

  .Autosuggest-List {
    @include media-breakpoint-down(sm) {
      line-height: $cds-line-height-large;
      margin-bottom: $cds-spacing-1;
    }
  }

  .Autosuggest-Title {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .Autosuggest-ListLink {
    @include media-breakpoint-down(xs) {
      padding: 0 $cds-spacing-1;
    }
  }
}

.Autosuggest-InkFinderLink {
  margin: $cds-spacing-1;

  &::before {
    @include cds-border-bottom-dashed;

    content: '';
    display: block;
    height: 1px;
    width: 100%;
  }

  .InkFinderLink {
    margin-top: $cds-spacing-1;
  }
}
