@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.CodeScannerModal {
  background-color: rgb(0 0 0 / 60%);

  .cds-NewModal-Content {
    height: calc(100% - $cds-spacing-6);
    padding: $cds-spacing-3;
  }
}

.CodeScannerModal-Title {
  @include cds-pos(absolute, $top: $cds-sizing-0_5);
  @include cds-typescale(0);

  align-items: center;
  color: $cds-color-white;
  display: flex;
  height: $cds-sizing-4;
  justify-content: center;
  width: calc(100% - #{$cds-spacing-2});
}

.CodeScannerModal-ContentWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.CodeScannerModal-Content {
  max-width: $cds-sizing-40;
  width: 100%;

  .cds-ProductBoxWrapper {
    margin-bottom: $cds-spacing-1;
    margin-left: 0;
    margin-right: 0;
  }
}

.CodeScannerModal-Alert {
  &.cds-Alert--Info {
    & .cds-Alert-Icon {
      left: $cds-spacing-3;
      margin-top: $cds-spacing-0_5 * 0.5;
    }

    & .cds-Alert-Title,
    & .cds-Alert-Content {
      color: $cds-color-white;
      text-align: center;
    }

    & .cds-Alert-Title {
      @include cds-typescale(3);

      margin-bottom: $cds-sizing-2;
    }
  }
}

.CodeScannerModal-AddToCart,
.CodeScannerModal-ScanAnotherProduct {
  margin-bottom: $cds-spacing-1;
}
