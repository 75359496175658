@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import './design-tokens';

.FullHiddenPrice {
  align-items: center;
  color: $full-hidden-price-color;
  display: flex;
}

.FullHiddenPrice-Icon {
  @include cds-box($cds-sizing-2_5);

  fill: $full-hidden-price-icon-fill;
  flex-shrink: 0;
  margin-left: $cds-spacing-1_5;
  margin-right: $cds-spacing-0_5;

  @include media-breakpoint-down(xs) {
    margin-left: $cds-spacing-1;
  }
}

.FullHiddenPrice-Info {
  @include cds-typescale(small, $line-height: $cds-line-height-list);

  pointer-events: none;

  .cds-ButtonLink {
    pointer-events: auto;
  }

  @include media-breakpoint-down(xs) {
    @include cds-typescale(xsmall, $line-height: $cds-line-height-condensed);
  }
}

.FullHiddenPrice-Currency {
  @include cds-typescale(5, $line-height: $cds-line-height-large);

  text-transform: uppercase;
}
