@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.cds-cms-NewsroomHeader-LeadText {
  @include cds-typescale(2, 2);
}

.cds-cms-NewsroomHeader-Title {
  margin: $cds-spacing-1 0 $cds-spacing-4;
}
