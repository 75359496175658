@import '@client/app/components/ErrorBoundary/AppCrash/design-tokens';

@font-face {
  font-family: 'Open Sans';
  font-stretch: 75% 100%;
  font-style: normal;
  font-weight: 300 800;
  src: url('/src/core/public/assets/fonts/open-sans/OpenSans-VariableFont.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-stretch: 75% 100%;
  font-style: italic;
  font-weight: 300 800;
  src: url('/src/core/public/assets/fonts/open-sans/OpenSans-Italic-VariableFont.ttf');
}

@keyframes shake {
  8%,
  41% {
    transform: translateX(-10px);
  }

  25%,
  58% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-5px);
  }

  92% {
    transform: translateX(5px);
  }

  0%,
  100% {
    transform: translateX(0);
  }
}

.AppCrash {
  align-items: center;
  background-color: $app-crash-bg;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.AppCrash-TopBar {
  background-color: $app-crash-top-bar-bg;
  height: $cds-sizing-3;
  width: 100%;
}

.AppCrash-LogoLink {
  margin-bottom: $cds-spacing-6;
}

.AppCrash-Logo {
  max-height: $cds-sizing-11;
  width: $cds-sizing-25;
}

.AppCrash-Image {
  animation: shake 500ms 1s;
}

.AppCrash-Content {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  padding: $cds-spacing-4;
  text-align: center;

  .AppCrash-ErrorTitle {
    color: $app-crash-error-title-color;
    margin-bottom: $cds-spacing-4;
    margin-top: $cds-spacing-0_5;
  }

  h1 {
    @include cds-typescale(h1);

    color: $app-crash-heading-color;
    margin: $cds-spacing-4 0 0;
  }

  p {
    @include cds-typescale(body);

    color: $app-crash-color;
    margin-bottom: $cds-spacing-2;
  }

  button {
    @include cds-typescale(1);

    background: $app-crash-button-bg;
    border: 0;
    border-radius: $cds-border-radius-global;
    color: $app-crash-button-color;
    font-weight: $cds-font-weight-semibold;
    height: $cds-sizing-5;
    margin: 0;
    padding: 0 $cds-spacing-8;
  }
}
