@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '@client/app/components/App/Header/Autosuggest/design-tokens';

.Autosuggest-BodyCell {
  .cds-Table--Bordered & {
    padding: $cds-sizing-0_5 $cds-sizing-1;
  }
}

.Autosuggest-ProductRow--Active {
  background-color: $autosuggest-list-link-hover-bg;
}

.Autosuggest-AddToCart {
  display: none;

  &.cds-Button {
    margin-left: auto;
  }

  &.CertificationInfo {
    display: block;
  }
}

.cds-DataTable-BodyRow {
  &.Autosuggest-ProductRow--Active {
    .Autosuggest-AddToCart {
      display: flex;
    }
  }
}

.Autosuggest-BodyCell--Image {
  height: $autosuggest-cell-image-height;
  width: $autosuggest-cell-image-width;
}

.Autosuggest-BodyCell--Title {
  max-width: 0;
}

.Autosuggest-BodyCellTitle {
  @include cds-link-colors($cds-color-text-primary, $cds-color-text-primary, $cds-color-text-body);

  display: block;
  font-weight: $cds-font-weight-semibold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.Autosuggest-BodyCellWrapper {
  align-items: center;
  display: flex;
}

.Autosuggest-BodyCellFeature {
  @include cds-typescale(xsmall, 0, $cds-line-height-condensed);

  display: inline-block;
}

.Autosuggest-BodyCellFeature--Sku {
  margin-right: $cds-spacing-1_5;
}

.Autosuggest-BodyCell--Price {
  text-align: right;
  width: $cds-sizing-13;
}

.Autosuggest-BodyCellHiddenPrice {
  justify-content: flex-end;
}

.Autosuggest-BodyCell--AddToCart {
  text-align: right;
  width: $cds-sizing-6;
}

.Autosuggest-BodyCell--StockCount {
  text-align: right;
  width: $cds-sizing-8;
}

.Autosuggest-BodyCellStockCount {
  @include cds-typescale(body);
}

// Mobile

.Autosuggest-BodyCellPrice--Mobile,
.Autosuggest-BodyCellHiddenPrice--Mobile {
  display: none;

  @include media-breakpoint-down(xs) {
    display: flex;
    margin-left: $cds-spacing-2;
  }
}

.Mobile {
  .Autosuggest-BodyCell {
    @include media-breakpoint-down(xs) {
      padding: $cds-sizing-0_5;
    }
  }

  .Autosuggest-AddToCart {
    display: block;
  }

  .Autosuggest-BodyCell--StockCount {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .Autosuggest-BodyCellFeature--Sku {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .Autosuggest-BodyCell--Price {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}
