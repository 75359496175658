@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.cds-cms-Form-DataTable {
  margin: $cds-spacing-2 0;
}

.cds-cms-Form-Submit {
  margin-top: $cds-spacing-2;
}

.cds-cms-Form-TableError {
  @include cds-typescale(xsmall);

  color: $cds-color-error;
  display: inline-block;
  padding: $cds-spacing-0_5 0;
}

.cds-cms-Form-TableLabel {
  @include cds-typescale(small);
}

.cds-cms-Form-FileInfo {
  @include cds-typescale(xsmall);

  margin: $cds-spacing-2;
}

.cds-cms-Form-FileError {
  @include cds-typescale(xsmall);

  color: $cds-color-error;
}
