[class*='cds-Teaser--DMK'].Banner {
  .Banner-ContentInner {
    flex: 1 1 auto;
  }

  .Banner-Title {
    margin-bottom: 1ch;
  }

  .Banner-Text {
    margin-bottom: 2ch;
  }
}
