@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.FailedProductBox {
  align-items: center;
  display: flex;
}

.FailedProductBox-ImagePlaceholder {
  flex-shrink: 0;
}

.FailedProductBox-Icon {
  fill: $cds-color-warning;
}

.FailedProductBox--List {
  &.cds-ProductBox {
    justify-content: start;

    @include media-breakpoint-down(xs) {
      padding-top: $cds-spacing-2;
    }
  }

  .FailedProductBox-ImagePlaceholder {
    height: $cds-sizing-8;
    margin-left: $cds-spacing-6;
    margin-right: $cds-spacing-6;
    width: $cds-sizing-8;

    @include media-breakpoint-down(xs) {
      margin-left: 0;
      margin-right: $cds-spacing-2;
    }
  }
}

.FailedProductBox--Table {
  .FailedProductBox-ImagePlaceholder {
    height: $cds-sizing-4;
    margin-left: $cds-spacing-1;
    margin-right: $cds-spacing-3_5;
    width: $cds-sizing-4;
  }
}

.FailedProductBox--Tile {
  .FailedProductBox-ImagePlaceholder {
    height: $cds-sizing-20;
    margin-bottom: $cds-spacing-3;
    width: $cds-sizing-20;
  }
}
