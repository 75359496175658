@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';

.ServerFailPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: $cds-spacing-11;
  padding: $cds-spacing-4;
  width: 100%;
}

.ServerFailPage-Icon {
  margin-bottom: $cds-spacing-1;

  & .cds-Icon {
    fill: $cds-color-warning;
  }
}

.ServerFailPage-Header {
  margin-bottom: $cds-spacing-0_5;
  text-align: center;
}

.ServerFailPage-Subheader {
  @include cds-typescale(body);

  font-weight: normal;
  margin-bottom: $cds-spacing-4;

  & span {
    font-weight: bold;
  }
}

.ServerFailPage-TextLine {
  margin-bottom: $cds-spacing-2;
  text-align: center;
}

.ServerFailPage-Button {
  width: $cds-sizing-30;
}
