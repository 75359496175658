@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@client/product/components/ProductData/Labels/design-tokens';

.Labels-Container {
  display: flex;
}

.Labels-Label {
  @include cds-typescale(-4);

  border-radius: $product-data-label-border-radius;
  height: $cds-sizing-2_5;
  margin-right: $cds-spacing-1;
  padding: $product-data-label-padding;
}

.Labels-Label--FullHeight {
  height: unset;
}

.Labels-LabelIcon {
  margin-right: $cds-spacing-1;
}
