@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import '@client/app/components/_design-tokens.scss';

.Footer {
  margin-top: auto;
}

.Footer-Sticky {
  .cds-Footer {
    .cds-FooterSub {
      padding-bottom: calc($cds-spacing-7 + env(safe-area-inset-bottom));

      @include media-breakpoint-down(md) {
        padding-right: $cds-spacing-8;
      }
    }
  }
}
