@import '~b2b-components/theme/styles/globals';

.ExternalLink {
  align-items: baseline;
  display: flex;
}

.ExternalLink-Icon {
  fill: $cds-color-text-body;
}
