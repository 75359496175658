@import '@lib/styles/globals';

$autosuggest-list-color: $cds-color-coal;
$autosuggest-list-link-color: $cds-color-coal;
$autosuggest-list-link-hover-bg: $cds-color-white;
$autosuggest-title: $cds-color-coal;
$autosuggest-content-bg: $cds-color-white;
$autosuggest-content-margin-left: $cds-spacing-30;
$autosuggest-arrow-left: $cds-spacing-3;
$autosuggest-arrow-left-md: $cds-spacing-23;
$autosuggest-arrow-left-mobile: $cds-spacing-3;
$autosuggest-icon-core-assortment-fill: $cds-color-aluminium;

// size of photos - exactly the sizes protect against "jumping"
$autosuggest-cell-image-height: 44px;
$autosuggest-cell-image-width: 73px;
