@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.cds-cms-NewsroomFooter {
  border-top: 1px solid $cds-color-silver;
}

.cds-cms-NewsroomFooter-TitleFiles {
  @include cds-typescale(2, 2);

  margin: $cds-spacing-2 0;
}

.cds-cms-NewsroomFooter-TitleLinks {
  @include cds-typescale(0);

  color: $cds-color-slate;
  font-weight: $cds-font-weight-bold;
  margin: $cds-spacing-3 0 $cds-spacing-2 $cds-spacing-1;
  width: 100%;
}

.cds-cms-NewsroomFooter-Link {
  display: inline;

  .ExternalLink-Icon {
    fill: $cds-color-link;
  }
}
