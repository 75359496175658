@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

// adjust product tiles sizing/grid for CMS content
.ProductListModule {
  @include cms-grid-item-size(100);
}

// sass-lint:disable no-vendor-prefixes
.ProductListModule-ListWrapper--Table {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
