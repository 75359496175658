@import 'node_modules/bootstrap/scss/mixins';
@import '~b2b-components/theme/styles/vendor/bootstrap-custom-variables';
@import '~b2b-components/theme/styles/globals';
@import '@client/app/components/_design-tokens.scss';

.UserArea {
  @include list-unstyled;

  display: flex;
  list-style-type: none;
  margin: 0;

  > li:first-of-type {
    margin-left: 0;
  }

  > li {
    display: inline-flex;
    margin-left: $cds-spacing-2;
  }

  .UserArea-MyAccountButton {
    .cds-Dashboard-Toggler-IconArrowDown {
      display: none;
    }
  }
}

.UserArea-Badge {
  button::before {
    background-color: $badge-color;
    border-color: white;
    border-radius: 100%;
    border-style: solid;
    border-width: 2px;
    content: "";
    display: inline-block;
    height: 14px;
    position: absolute;
    right: -6px;
    top: -6px;
    width: 14px;
  }
}

.UserArea-Li-MyAccount {
  align-items: center;
}

.UserArea-Icon-TooltipTrigger {
  display: flex;
  margin: 0 0 0 $cds-spacing-0_5;
  padding: 0;
}

.UserArea-MyAccountButton--ExpressOrder {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

// ---------------------------------------------
//  MOBILE HEADER COMPONENT
// ---------------------------------------------
.UserArea--Mobile {
  & > li {
    margin-left: $cds-spacing-1;
  }
}
