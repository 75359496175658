@import '~@client/common/components/ErrorPage/_design-tokens.scss';

.ErrorPage {
  align-items: center;
  display: flex;
  height: 100vh;
}

.ErrorPage-TopBar {
  background-color: $cds-header-top-bg;
  height: $cds-sizing-3;
  width: 100%;
}

.ErrorPage-Content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.ErrorPage-Logo {
  height: auto;
  margin-bottom: $cds-spacing-6;
  margin-right: 0;

  & .cds-ImgFluid {
    max-height: $cds-sizing-11;
    width: $cds-sizing-25;
  }
}

.ErrorPage-Image {
  margin-bottom: $cds-spacing-3_5;
}

.ErrorPage-Title {
  margin-bottom: $cds-spacing-0_5;
}

.ErrorPage-Subtitle {
  @include cds-typescale(body);

  margin-bottom: $cds-spacing-2;
}
