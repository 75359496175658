@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.Content--CMS {
  .cds-cms-SectionTitleContainer {
    margin: 0 auto;
    width: 100%;
  }
}
