@import 'node_modules/bootstrap/scss/mixins';
@import '@lib/styles/vendor/bootstrap-custom-variables';
@import '@lib/styles/globals';

.Desktop {
  padding-bottom: $cds-spacing-2;
  position: relative;

  .cds-Row {
    &:first-child {
      margin-bottom: $cds-spacing-1_5;
    }
  }
}

.Desktop-Flyout {
  margin-right: $cds-spacing-2;
}
